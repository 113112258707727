const styles = (theme) => ({
  bigRoot: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  smallRoot: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  labelSession: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  textItem: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  itemsContainer: {
    alignSelf: 'center',
  },
  numberColor: {
    backgroundColor: theme.palette.primary.main,
  },
})

export default styles
