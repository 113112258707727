import React from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import helpers from 'helpers'

import styles from '../VisitsTable/styles'

import { Box, Chip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(styles)

const TableRowVisitorCookies = ({ visitorCookie, ...rest }) => {
  const classes = useStyles()
  return (
    <TableRow {...rest}>
      <TableCell>
        <Box ml={2}>{visitorCookie?.cookieUnit?.id}</Box>
      </TableCell>
      <TableCell>{visitorCookie?.cookieUnit?.cookieGroup?.name}</TableCell>
      <TableCell>{visitorCookie?.cookieUnit?.name}</TableCell>
      <TableCell>
        <Chip
          label={helpers.visits.statusLabel(visitorCookie?.accepted)}
          icon={helpers.visits.statusIcon(visitorCookie?.accepted)}
          className={helpers.visits.statusChipColor(
            visitorCookie?.accepted,
            classes,
          )}
        />
      </TableCell>
    </TableRow>
  )
}

const VisitorsCookieTable = ({ visitorCookies, ...rest }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar options={{ useBothWheelAxes: true }}>
        <Table
          size="small"
          emptyMessage="Nenhum aceite de cookie encontrado"
          noWrap
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell width="10%">
                <Box ml={2}>Identificador</Box>
              </TableCell>
              <TableCell width="30%">Grupo do cookie</TableCell>
              <TableCell width="30%">Referência do cookie</TableCell>
              <TableCell width="30%">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visitorCookies.map((visitorCookie) => (
              <TableRowVisitorCookies
                visitorCookie={visitorCookie}
                key={visitorCookie?.cookieUnit?.id}
                hover
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

export default VisitorsCookieTable
