import React from 'react'

// import { routes } from 'Routes'

import {
  Button,
  AppBar,
  Toolbar,
  Link,
  Box,
  makeStyles,
  Divider,
  // Hidden,
  Typography,
} from '@material-ui/core'

import styles from './styles'

import Logo from 'images/logo-horizontal.png'

// import { MenuButton } from 'components'

// Remover cometários e implementar rotas quando o site www.leavening.com estiver pronto

const useStyles = makeStyles(styles)

const TopBar = ({ loginWithButton = () => {}, signUpButton = () => {} }) => {
  const classes = useStyles()

  return (
    <AppBar className={classes.appBar} elevation={2}>
      <Toolbar>
        <Box className={classes.root}>
          <Box>
            <img src={Logo} alt="logo-horizontal" className={classes.logo} />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-around">
            {/* <Hidden smDown> */}
            <Box
              display="flex"
              justifyContent="space-around"
              direction="row"
              // width="350px"
              alignItems="center"
            >
              <Box mr={2}>
                <Link
                  onClick={() => signUpButton()}
                  color="secondary"
                  className={classes.textButton}
                >
                  <Typography variant="h6">Cadastre-se</Typography>
                </Link>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box ml={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => loginWithButton()}
                  size="small"
                >
                  Acessar
                </Button>
              </Box>
            </Box>
            {/* </Hidden> */}
            {/* <Hidden mdUp>
                <MenuButton>
                  <Button onClick={() => loginWithButton()} fullWidth>Acessar</Button>
                  <Button fullWidth>Página Inicial</Button>
                  <Button fullWidth>Sobre Nós</Button>
                </MenuButton>
              </Hidden> */}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
