import React from 'react'

import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  TextField,
} from '@material-ui/core'

import * as service from 'service'

const DialogPrivacyPolicyLink = ({ open, setOpen = () => {} }) => {
  const snackbar = useSnackbar()
  const history = useHistory()

  const { response, isLoading, refresh } = useFetch(
    service.cookie.privacyPolicyLinks.get,
    {},
  )

  const privacyPolicy = response?.data?.privacyPolicyLinks[0]

  const { handleSubmit, errors, control } = useForm()

  const closeDialog = () => {
    setOpen(false)
    history.push(routes.home)
  }

  const onSubmit = async (data) => {
    if (!privacyPolicy?.id) {
      try {
        await service.cookie.privacyPolicyLinks.create({
          privacyPolicyLink: data,
        })

        snackbar.open({
          variant: 'success',
          message: 'Link da política de privacidade cadastrado com sucesso!',
        })
        refresh()
        closeDialog()
      } catch {
        snackbar.open({
          variant: 'error',
          message: 'Falha ao cadastrar link da política de privacidade!',
        })
        refresh()
        closeDialog()
      }
    } else {
      try {
        await service.cookie.privacyPolicyLinks.put({
          privacyPolicyLinkId: privacyPolicy.id,
          privacyPolicyLink: data,
        })

        snackbar.open({
          variant: 'success',
          message: 'Link da política de privacidade atualizado com sucesso!',
        })
        refresh()
        closeDialog()
      } catch {
        snackbar.open({
          variant: 'error',
          message: 'Falha ao atualizar link da política de privacidade!',
        })
        refresh()
        closeDialog()
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">Link da política de privacidade</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>
          <>
            {isLoading ? (
              <Box
                id="loadBox"
                display="flex"
                width="100%"
                minHeight="700px"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box mb={2}>
                  <Typography>
                    Para que seus clientes tenham acesso a sua política de
                    privacidade cadastre o Link da mesma abaixo
                  </Typography>
                </Box>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  id="privacy-policy-link-form"
                >
                  <Controller
                    name="url"
                    control={control}
                    defaultValue={privacyPolicy?.url || ''}
                    as={
                      <TextField
                        label="URL da política de privacidade"
                        variant="outlined"
                        fullWidth
                        color="primary"
                        error={!!errors.url}
                        helperText={errors?.url?.message ?? ''}
                      />
                    }
                  />
                </form>
              </>
            )}
          </>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => closeDialog()}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form="privacy-policy-link-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogPrivacyPolicyLink
