const statusChipColor = (accepted, classes) => {
  if (accepted) {
    return classes.active
  }

  if (!accepted) {
    return classes.notActive
  }

  return null
}

export default statusChipColor
