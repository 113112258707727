import { colors } from '@material-ui/core'

const styles = (theme) => ({
  active: {
    backgroundColor: colors.green[500],
    color: colors.common.white,
    fontWeight: 700,
  },

  notActive: {
    backgroundColor: colors.grey[500],
    color: colors.common.white,
    fontWeight: 700,
  },
  containerCollapsableTable: {
    padding: 0,
    border: 0,
    '&:last-child': { padding: 0 },
  },
})

export default styles
