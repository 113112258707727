import React from 'react'

import {
  Grid,
  TextField,
  Box,
  DialogContent,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core'

import {
  Controller,
  FormContext,
  useForm,
  useFieldArray,
} from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'
import useCookieGroup from 'hooks/useCookieGroup'

import * as service from 'service'

import CookieUnitForm from './CookieUnitForm'

import styles from './styles'
const useStyles = makeStyles(styles)

const CookiesForm = ({
  cookieData = null,
  edit,
  children,
  isCookieGroup = true,
  setLoading = () => {},
  setOpen = () => {},
}) => {
  const classes = useStyles()
  const {
    cookieGroupId,
    loadData,
    setReloadUnitTable,
    reloadUnitTable,
    params,
  } = useCookieGroup()

  const defaultValues = {
    cookieUnitId: cookieData?.id,
    cookieGroupId: cookieGroupId,
    name: edit ? cookieData?.name : '',
    status: edit ? cookieData?.status : true,
    description: edit ? cookieData?.description : '',
    required: edit ? cookieData?.required : false,
  }

  if (isCookieGroup) {
    defaultValues['tag'] = edit ? cookieData?.tag : ''
    delete defaultValues['cookieUnitId']
  }

  const formMethods = useForm({
    defaultValues: defaultValues,
  })

  const { control, errors, handleSubmit } = formMethods

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'cookieUnits',
    keyName: 'key',
  })

  const snackbar = useSnackbar()

  const getSubmitMethod = () => {
    if (edit) {
      if (isCookieGroup) {
        return service.cookie.cookieGroups.put
      }
      return service.cookie.cookieGroups.putCookieUnit
    }
    if (isCookieGroup) {
      return service.cookie.cookieGroups.create
    }

    return service.cookie.cookieGroups.createCookieUnit
  }

  const onSubmit = async (values) => {
    const submitMethod = getSubmitMethod()

    try {
      setLoading(true)
      await submitMethod({ ...values })
      snackbar.open({
        message: `${isCookieGroup ? 'Grupo de cookies ' : 'Cookie '} ${
          edit ? 'editado' : 'criado'
        } com sucesso!`,
        variant: 'success',
      })
      setOpen(false)
      if (isCookieGroup) {
        await loadData(params)
      } else {
        setReloadUnitTable(!reloadUnitTable)
      }
      setLoading(false)
    } catch {
      snackbar.open({
        message: `Erro ao ${edit ? 'editar' : 'criar'} ${
          isCookieGroup ? 'grupo de cookies ' : 'cookie '
        }`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const removeCookieUnit = (index) => {
    remove(index)
  }

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.dialogContent}>
          <Box my={1}>
            <Grid container spacing={2}>
              {!isCookieGroup && (
                <Controller as={<></>} control={control} name="cookieUnitId" />
              )}
              <Controller as={<></>} control={control} name="cookieGroupId" />
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={
                        errors && errors.name && 'Preencha esse campo'
                      }
                      fullWidth
                    />
                  }
                  rules={{ required: true }}
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              {!isCookieGroup && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        label="Descrição"
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.description}
                        helperText={
                          errors && errors.description && 'Preencha esse campo'
                        }
                        fullWidth
                      />
                    }
                    rules={{ required: true }}
                    control={control}
                    name="description"
                  />
                </Grid>
              )}
              {isCookieGroup && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        label="TAG"
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.tag}
                        helperText={
                          errors && errors.tag && 'Preencha esse campo'
                        }
                        fullWidth
                      />
                    }
                    rules={{ required: true }}
                    control={control}
                    name="tag"
                    mode="onBlur"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  as={
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label="Ativo"
                    />
                  }
                  control={control}
                  name="status"
                  mode="onBlur"
                />
                <Controller
                  as={
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label="Necessário"
                    />
                  }
                  control={control}
                  name="required"
                  mode="onBlur"
                />
              </Grid>
              {isCookieGroup && !edit && (
                <>
                  <Box width="100%" ml={1}>
                    <Box width="100%" my={2}>
                      <Divider />
                    </Box>
                    <Typography variant="h4">Cookies do grupo</Typography>
                  </Box>
                  {fields.length > 0 &&
                    fields.map((item, index) => (
                      <Grid item xs={12} key={item.key}>
                        <CookieUnitForm
                          key={item.key}
                          cookieUnit={item}
                          index={index}
                          removeCookieUnit={removeCookieUnit}
                        />
                        <Divider />
                      </Grid>
                    ))}
                  <Box display="flex" justifyContent="flex-end" my={2}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        append({
                          id: '',
                          name: '',
                          status: true,
                          required: false,
                        })
                      }
                    >
                      Adicionar cookie ao grupo
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </Box>
        </DialogContent>
        {children}
      </form>
    </FormContext>
  )
}

export default CookiesForm
