export const getBaseURL = (project) => envsBaseUrl[project][env]

const env = process.env.REACT_APP_API || 'development'

const companyToken = {
  development: '50b84821d21ca77252d84ba3abb757f9',
  sandbox: '85081a949e63b592cc511566374365b2',
  production: '34990fb9004ad5c64ca3d94ca9ce0348',
}

export const COMPANY_TOKEN_LOGIN_WITH_ID = companyToken[env]

const envsBaseUrl = {
  cookie: {
    development: 'http://localhost:3003',
    sandbox: 'https://api.cookies.sandbox.leavening.com',
    production: 'https://api.cookies.leavening.com',
  },
  auth: {
    development: 'http://172.27.0.2:5000/auth.js',
    sandbox: 'https://auth.sandbox.oneid.com.br/auth.js',
    production: 'https://auth.oneid.com.br/auth.js',
  },
  oneId: {
    development: 'http://localhost:3006',
    sandbox: 'https://app.sandbox.oneid.com.br',
    production: 'https://app.oneid.com.br',
  },
}
