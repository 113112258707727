import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const date = (date) => {
  if (!date) {
    return '-'
  }

  return moment(date).utc().format('DD/MM/YYYY')
}

export default date
