import React from 'react'

import { Container, Button, Card, Box } from '@material-ui/core'

import { Page, ContentHeader, FilterButton, Filters } from 'components'

import CookiesTable from './components/CookiesTable'

import FormModal from './components/FormModal'

import useFilter from 'hooks/useFilter'

import useCookieGroup from 'hooks/useCookieGroup'

const ManageCookiesMain = () => {
  const {
    modalOpen,
    setModalOpen,
    isCookieGroup,
    handleNewGroup,
    cookieGroup,
    cookieUnit,
  } = useCookieGroup()
  const filter = useFilter()

  return (
    <Page title="Gerenciar meus cookies">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Sistema"
          title="Cookies"
          subtitle="Gerenciar meus cookies"
        >
          <Button variant="contained" color="primary" onClick={handleNewGroup}>
            Novo grupo de cookies
          </Button>
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Card>
          <CookiesTable filter={filter} />
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Nome do grupo" name="name" />
          <input textfieldinput="true" label="TAG" name="tag" />
          <input
            datepickerinput="true"
            label="Data de criação"
            name="createdAtGt"
            data-min
          />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value={true}>Ativo</option>
            <option value={false}>Inativo</option>
          </select>
          <select textfieldinput="true" label="Necessário" name="required">
            <option value=""></option>
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </select>
        </Filters>
        <FormModal
          cookieData={isCookieGroup ? cookieGroup : cookieUnit}
          open={modalOpen}
          setOpen={setModalOpen}
          isCookieGroup={isCookieGroup}
        />
      </Container>
    </Page>
  )
}

export default ManageCookiesMain
