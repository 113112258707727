const styles = (theme) => ({
  firstContainer: {
    width: '100%',
    marginTop: theme.spacing(16),
    // marginBottom: 24, // voltar quando tiver um comentário
    marginBottom: theme.spacing(16),
  },
  secondContainer: {
    width: '100%',
    marginBottom: theme.spacing(16),
  },
  title: {
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  fontStyle: {
    fontSize: 16,
    fontWeight: 400,
  },
})

export default styles
