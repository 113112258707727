const styles = (theme) => ({
  firstContainer: {
    width: '100%',
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(3),
  },
  secondContainer: {
    width: '100%',
    marginBottom: theme.spacing(20),
  },
  title: {
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  fontStyle: {
    fontSize: 16,
    fontWeight: 400,
  },
})

export default styles
