import React from 'react'

import { Box, Switch, Typography } from '@material-ui/core'

const SwitchDetailPreview = ({
  text,
  buttonBackgroundColor,
  mainTextColor,
  classes,
  disabled = false,
  ...rest
}) => {
  const opacity = disabled ? { opacity: '0.5' } : {}

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Typography
        variant="h6"
        className={classes.typography}
        style={{ color: mainTextColor }}
      >
        {text || 'Cookie'}
      </Typography>
      <Switch
        disabled={disabled}
        checked
        style={{
          color: buttonBackgroundColor,
          ...opacity,
        }}
      />
    </Box>
  )
}

export default SwitchDetailPreview
