import api from 'service/api'

const cookieAPI = api.create('cookie')
const cookieAPIAccount = api.create('cookie', true, true)

const get = async ({ userId = '', ...params }) => {
  return await cookieAPI.get(`/users/${userId}`, { params })
}

const create = async ({ ...data }) => {
  return await cookieAPI.post('/users', data)
}

const put = async ({ userId = '', ...data }) => {
  return await cookieAPI.put(`/users/${userId}`, data)
}

const newPassword = async (data) => {
  return await cookieAPI.put(`/users/password`, { user: data })
}

const permissions = async () => {
  return await cookieAPI.get('/user/permissions')
}

const me = async () => {
  return await cookieAPI.get('/users/me')
}

const acceptTerms = async () => {
  return await cookieAPI.get('/users/users_steps/accept_terms')
}

const changeStatus = async ({ userId }) => {
  return await cookieAPI.put(`/users/${userId}/change_status`)
}

const list = async ({ ...params }) => {
  return await cookieAPIAccount.get('/users', {
    params,
  })
}

const remove = async ({ userId }) => {
  return await cookieAPI.delete(`users/${userId}`)
}

const users = {
  get,
  create,
  put,
  newPassword,
  permissions,
  me,
  acceptTerms,
  changeStatus,
  list,
  remove,
}

export default users
