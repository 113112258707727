import api from 'service/api'

const cookieAPI = api.create('cookie')

const get = async () => {
  return await cookieAPI.get(`documentation_links/`)
}

const documentationLinks = { get }

export default documentationLinks
