const styles = (theme) => ({
  logo: {
    width: '164px',
    height: '50px !important',
    marginBottom: theme.spacing(3),
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  grayContainer: {
    width: '100%',
    backgroundColor: '#F4F6F8',
  },
  rootBody: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export default styles
