import React, { useState, useEffect } from 'react'

import CustomCookieBoxContext from '../contexts/CustomCookieBoxContext'

import * as service from 'service'

import { ACCOUNT_TOKEN } from 'service/constants'

const CustomCookieBoxProvider = ({ children }) => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState()

  useEffect(() => {
    loadData()

    //eslint-disable-next-line
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    const token = localStorage.getItem(ACCOUNT_TOKEN)
    const response = await service.cookie.cookieComponents.getCustomBox({
      token,
    })
    setData(response?.data?.customCookieBox)
    setIsLoading(false)
  }
  return (
    <CustomCookieBoxContext.Provider
      value={{
        data,
        setData,
        loadData,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </CustomCookieBoxContext.Provider>
  )
}

export default CustomCookieBoxProvider
