import statusLabel from './statusLabel'
import statusIcon from './statusIcon'
import statusChipColor from './statusChipColor'

const acceptedCookies = {
  statusChipColor,
  statusLabel,
  statusIcon,
}

export default acceptedCookies
