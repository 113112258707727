import React, { useState, useEffect } from 'react'

import * as service from 'service'
import AuthContext from 'contexts/AuthContext'

import {
  Home as HomeIcon,
  ThumbsUp as ThumbsUPIcon,
  Code as CodeIcon,
  Link as LinkIcon,
  PenTool as PenToolIcon,
  Globe as GlobeIcon,
  Users as UsersIcon,
} from 'react-feather'

import { BubbleChart as BubbleChartIcon } from '@material-ui/icons'

const AuthProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [account, setAccount] = useState(null)
  const [menuItems, setMenuItems] = useState([])
  const [documentationLinks, setDocumentationLinks] = useState([])

  useEffect(() => {
    if (service.cookie.auth.isAuthenticated()) {
      loadDataWithAnimation()
    }

    // eslint-disable-next-line
  }, [])

  const loadData = async () => {
    const [response, documentationLinksResponse] = await Promise.all([
      service.cookie.users.me(),
      service.cookie.documentationLinks.get(),
    ])

    setUser(response?.data?.user)
    setAccount(response?.data?.user?.account)
    setDocumentationLinks(documentationLinksResponse?.data?.documentationLinks)

    service.cookie.auth.setAccountToken(response?.data?.user?.account?.token)

    const menuItems = [
      {
        subheader: 'Cookies',
        items: [
          {
            title: 'Inicio',
            icon: HomeIcon,
            href: '/home',
          },
          {
            title: 'Consentimentos',
            icon: ThumbsUPIcon,
            href: '/visits',
          },
          {
            title: 'Gerenciar meus cookies',
            icon: BubbleChartIcon,
            href: '/cookies',
          },
          {
            title: 'Código do aviso',
            icon: CodeIcon,
            href: '/home?scriptIntegration=true',
          },
          {
            title: 'Link da política de privacidade',
            icon: LinkIcon,
            href: '/home?privacyPolicyLink=true',
          },
          {
            title: 'Personalizar aviso',
            icon: PenToolIcon,
            href: '/customize-cookie-box',
          },
          {
            title: 'Usuários',
            icon: UsersIcon,
            href: '/users',
          },
          {
            title: 'Domínios',
            icon: GlobeIcon,
            href: '/domains',
          },
        ],
      },
    ]

    setMenuItems(menuItems)
  }

  const loadDataWithAnimation = async () => {
    setIsLoading(true)
    setLoaded(false)

    try {
      await loadData()
    } finally {
      setLoaded(true)
      setIsLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        account,
        setAccount,
        loaded,
        isLoading,
        menuItems,
        documentationLinks,
        loadDataWithAnimation,
        loadData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
