import React from 'react'

import { Box, Container, Typography, Grid, makeStyles } from '@material-ui/core'

import { Link } from 'react-router-dom'

import styles from './styles'

import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const MainComponent = () => {
  const classes = useStyles()

  return (
    <Container>
      <Box
        display="flex"
        alignItems="center"
        textAlign="justify"
        minHeight="72vh"
      >
        <Grid container justify="space-between">
          <Grid item md={5} lg={5} sm={12} xs={12}>
            <Box mt={2} mb={5}>
              <Typography variant="h1" className={classes.title}>
                Bem vindo!
              </Typography>
              <Box mt={5}>
                <Box>
                  <Typography variant="subtitle1" color="textPrimary">
                    Ao utilizar o Leavening Cookies, seu site se torna adequado
                    a diversas leis sobre proteção de dados, como a LGPD.
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Cuide das informações de seus visitantes de forma clara e
                    transparente.
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Acesse{' '}
                    <Link
                      to="?scriptIntegration=true"
                      className={classes.textLinkColor}
                    >
                      Código do aviso
                    </Link>{' '}
                    e siga os passos para inserir a caixa de cookies em seu
                    site, ou então a{' '}
                    <Link
                      to={routes.visits.main}
                      className={classes.textLinkColor}
                    >
                      Consentimento
                    </Link>{' '}
                    para ver os visitantes e as preferencias de cookies
                    confirmadas.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <img
              alt="Em desenvolvimento"
              src="/images/preferences.svg"
              className={classes.image}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default MainComponent
