const styles = () => ({
  collapsableCell: {
    padding: 0,
    border: 0,
    '&:last-child': {
      padding: 0,
    },
  },
})

export default styles
