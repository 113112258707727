import React, { useState } from 'react'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

import {
  Button,
  Container,
  Box,
  Grid,
  Card,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'

import { Skeleton } from '@material-ui/lab'

import { Page, ContentHeader, FilterButton, Filters } from 'components'

import * as service from 'service'

import { useTheme } from '@material-ui/styles'

import { TableUsers, DialogUser } from './components'

const Main = () => {
  const filter = useFilter()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [dialogUserOpen, setDialogUserOpen] = useState(false)
  const {
    perPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.cookie.users.list,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Todos os usuários">
      <Container maxWidth={false}>
        <ContentHeader menu="Sistema" title="Cookies" subtitle="Usuários">
          <Box pl={1} display="flex">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDialogUserOpen(true)}
            >
              Novo
            </Button>
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </Box>
        </ContentHeader>
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <TableUsers
                users={response?.data?.users}
                isLoading={isLoading}
                onEvent={refresh}
              />
            </Grid>
          </Box>
          {response?.data?.meta && (
            <Box px={2} display="flex" justifyContent="flex-end">
              {!isLoading && response ? (
                <TablePagination
                  component="div"
                  count={response?.data?.meta?.totalCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              ) : (
                <Box py="11px">
                  <Skeleton variant="rect" width={200} height={30} />
                </Box>
              )}
            </Box>
          )}
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Nome" name="name" />
        </Filters>
      </Container>
      <DialogUser
        open={dialogUserOpen}
        setOpen={setDialogUserOpen}
        onEvent={refresh}
      />
    </Page>
  )
}

export default Main
