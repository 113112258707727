import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from '@material-ui/core'

import MainComponent from './components/MainComponent'
import DialogIntegrationScript from './components/DialogIntegrationScript'
import DialogPrivacyPolicyLink from './components/DialogPrivacyPolicyLink'

import qs from 'query-string'

const Home = () => {
  const location = useLocation()
  const search = qs.parse(location.search)
  const [dialogIntegrationScript, setDialogIntegrationScript] = useState(false)
  const [dialogPrivacyPolicyLink, setDialogPrivacyPolicyLink] = useState(false)

  useEffect(() => {
    setDialogIntegrationScript(search.scriptIntegration === 'true')
    setDialogPrivacyPolicyLink(search.privacyPolicyLink === 'true')
  }, [search.scriptIntegration, search.privacyPolicyLink])

  return (
    <Box>
      <MainComponent />
      <DialogIntegrationScript
        open={dialogIntegrationScript}
        setOpen={setDialogIntegrationScript}
      />
      <DialogPrivacyPolicyLink
        open={dialogPrivacyPolicyLink}
        setOpen={setDialogPrivacyPolicyLink}
      />
    </Box>
  )
}

export default Home
