import auth from './auth'
import users from './users'
import privacyPolicyLinks from './privacyPolicyLinks'
import cookieComponents from './cookieComponents'
import visits from './visits'
import websites from './websites'
import cookieGroups from './cookieGroups'
import documentationLinks from './documentationLinks'

const cookie = {
  auth,
  users,
  privacyPolicyLinks,
  cookieComponents,
  visits,
  websites,
  cookieGroups,
  documentationLinks,
}

export default cookie
