import React, { useEffect } from 'react'

import {
  Box,
  Grid,
  Typography,
  Link,
  Button,
  makeStyles,
} from '@material-ui/core'

import { Link as RouterLink } from 'react-router-dom'

import SwitchDetailsPreview from '../SwitchDetailsPreview'

import useCookieGroup from 'hooks/useCookieGroup'

import { X as XIcon } from 'react-feather'

import { css } from 'styled-components'
import styles from './styles'

const useStyles = makeStyles(styles)

const CookieBoxPreview = ({ watch, setFixed = () => {}, fixed = false }) => {
  const classes = useStyles()
  const colors = watch([
    'backgroundColor',
    'mainTextColor',
    'buttonBackgroundColor',
    'buttonTextColor',
  ])

  const { data, setParams, params, loadData } = useCookieGroup()
  useEffect(() => {
    setParams({ perPage: 10, status: true })
    loadData({ perPage: 10, status: true })
    //eslint-disable-next-line
  }, [params?.perPage])

  const mountColor = (color) => (color?.hex ? `#${color.hex}` : color)
  const fixedStyles = fixed ? classes.fixedStyles : classes.unfixedStyles

  return (
    <div className={fixedStyles}>
      <style>{css`
        .MuiSwitch-track {
          background-color: ${mountColor(
            colors?.buttonBackgroundColor,
          )} !important;
        }

        .list-cookies::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }

        .list-cookies::-webkit-scrollbar-thumb {
          background: ${mountColor(colors?.buttonBackgroundColor)};
          border-radius: 8px;
        }

        .list-cookies::-webkit-scrollbar-track {
          background: ${mountColor(colors?.buttonTextColor)};
          border-radius: 8px;
        }
      `}</style>
      {fixed && (
        <Box
          className="list-cookies"
          mb={2}
          maxHeight="50vh"
          minWidth="250px"
          maxWidth="400px"
          borderRadius="5px"
          style={{
            backgroundColor: mountColor(colors?.backgroundColor),
            overflowY: 'scroll',
          }}
        >
          <Box display="flex" justifyContent="center" pt={1}>
            <Grid item xs={11}>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="h6"
                  className={classes.typography}
                  style={{ color: mountColor(colors?.mainTextColor) }}
                >
                  Cookies a serem utilizados
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box
                mr={1}
                display="flex"
                borderRadius="5px"
                style={{
                  backgroundColor: mountColor(colors?.buttonBackgroundColor),
                }}
                alignItems="center"
              >
                <XIcon />
              </Box>
            </Grid>
          </Box>
          <Box mt={1}>
            {data &&
              data.map((cookieGroup, index) => (
                <Box key={cookieGroup?.id}>
                  <SwitchDetailsPreview
                    mx={2}
                    disabled={cookieGroup?.required}
                    classes={classes}
                    text={cookieGroup?.name}
                    mainTextColor={mountColor(colors?.mainTextColor)}
                    buttonBackgroundColor={mountColor(
                      colors?.buttonBackgroundColor,
                    )}
                  />
                  {(index === 0 || index === 3) &&
                    cookieGroup?.cookieUnits.map((cookieUnit) => (
                      <SwitchDetailsPreview
                        key={`unit-${cookieUnit?.id}`}
                        ml={2}
                        mr={4}
                        disabled={cookieUnit?.required || cookieGroup?.required}
                        classes={classes}
                        text={cookieUnit?.name}
                        mainTextColor={mountColor(colors?.mainTextColor)}
                        buttonBackgroundColor={mountColor(
                          colors?.buttonBackgroundColor,
                        )}
                      />
                    ))}
                </Box>
              ))}
          </Box>
        </Box>
      )}
      <Box
        width="100%"
        p={4}
        minHeight="15vh"
        style={{
          backgroundColor: mountColor(colors?.backgroundColor),
          pointerEvents: 'all',
        }}
      >
        <Grid container>
          <Box my={2}>
            <Typography
              variant="body1"
              className={classes.typography}
              style={{ color: mountColor(colors?.mainTextColor) }}
            >
              Nós usamos cookies em nosso site. Os cookies são utilizados ​​para
              disponibilizar as funcionalidades e o uso do nosso site, além de
              contribuir para nossas análises e melhorar a usabilidade. Ao
              aceitar e continuar a usar este site, você concorda com o uso dos
              cookies.
            </Typography>
          </Box>
          <Box mb={1}>
            <Link to="#" component={RouterLink} className={classes.link}>
              <Typography
                className={classes.typography}
                style={{ color: mountColor(colors?.buttonBackgroundColor) }}
              >
                Ler política de privacidade
              </Typography>
            </Link>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Button
              disableElevation
              disableRipple
              className={classes.button}
              style={{
                backgroundColor: mountColor(colors?.buttonBackgroundColor),
              }}
            >
              <Typography
                className={classes.typography}
                style={{ color: mountColor(colors?.buttonTextColor) }}
                onClick={() => setFixed(!fixed)}
              >
                Detalhes
              </Typography>
            </Button>
            <Button
              disableElevation
              disableRipple
              className={classes.button}
              style={{
                backgroundColor: mountColor(colors?.buttonBackgroundColor),
              }}
            >
              <Typography
                className={classes.typography}
                style={{ color: mountColor(colors?.buttonTextColor) }}
              >
                Aceitar e continuar
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Box>
    </div>
  )
}

export default CookieBoxPreview
