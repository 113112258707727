import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Typography,
  TableContainer,
  Button,
  TablePagination,
  useMediaQuery,
  Box,
  Grid,
  Collapse,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons'

import { useTheme } from '@material-ui/styles'

import { MenuButton } from 'components'
import CookieUnitTable from './CookieUnitTable'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import styles from './styles'

import usePagination from 'hooks/usePagination'
import useCookieGroup from 'hooks/useCookieGroup'

const useStyles = makeStyles(styles)

const CookieGroupRow = ({ cookieGroup }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const snackbar = useSnackbar()

  const { loadData, handleNewUnit, handleEditGroup, params } = useCookieGroup()

  const changeGroupStatus = async () => {
    try {
      await service.cookie.cookieGroups.changeGroupStatus({
        cookieGroupId: cookieGroup?.id,
      })
      snackbar.open({
        message: `Grupo de cookies ${
          cookieGroup.status ? 'in' : ''
        }ativado com sucesso`,
        variant: 'success',
      })
      await loadData(params)
    } catch {
      snackbar.open({
        message: `Ocorreu um erro ao ${
          cookieGroup.status ? 'in' : ''
        }ativar o grupo de cookies`,
        variant: 'error',
      })
    }
  }

  const changeRequired = async () => {
    try {
      await service.cookie.cookieGroups.put({
        cookieGroupId: cookieGroup?.id,
        cookieGroup: { required: !cookieGroup.required },
      })
      snackbar.open({
        message: `Grupo de cookies ${cookieGroup.name} foi marcado como ${
          cookieGroup.required ? 'não ' : ''
        } necessário`,
        variant: 'success',
      })
      await loadData(params)
    } catch {
      snackbar.open({
        message: `Ocorreu um erro ao marcar o grupo de cookies como ${
          cookieGroup.name
        } ${cookieGroup.required ? 'não' : ''} necessário`,
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography>{cookieGroup.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{cookieGroup.tag}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{cookieGroup.status ? 'Ativo' : 'Inativo'}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{cookieGroup.required ? 'Sim' : 'Não'}</Typography>
        </TableCell>
        <TableCell align="right">
          <MenuButton>
            <Button onClick={() => handleNewUnit(cookieGroup?.id)} fullWidth>
              Adicionar cookie
            </Button>
            <Button onClick={() => handleEditGroup(cookieGroup)} fullWidth>
              Editar
            </Button>
            <Button onClick={changeRequired} fullWidth>{`Marcar como  ${
              cookieGroup.required ? 'não' : ''
            }  necessário`}</Button>
            <Button onClick={changeGroupStatus} fullWidth>
              {cookieGroup.status ? 'Inativar' : 'Ativar'}
            </Button>
          </MenuButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapsableCell} colSpan={6}>
          <Collapse in={open} timeout="auto" mountOnEnter>
            <CookieUnitTable
              cookieGroupId={cookieGroup?.id}
              cookieUnits={cookieGroup?.cookieUnits}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const CookiesTable = ({ filter }) => {
  const {
    perPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination(10)

  const {
    data,
    meta,
    loadData,
    isLoading,
    setParams,
    params,
  } = useCookieGroup()

  useEffect(() => {
    setParams({ perPage, page, ...{ ...filter.filters } })
    loadData({ perPage, page, ...{ ...filter.filters } })
    //eslint-disable-next-line
  }, [perPage, page, filter.filters])

  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  return (
    <>
      <Box display="flex" width="100%" mt={2}>
        <Grid container>
          <Box width="100%">
            <PerfectScrollbar>
              <TableContainer>
                <Table
                  size="small"
                  emptyMessage="Nenhum grupo de cookies foi encontrado"
                  noWrap
                  isLoading={isLoading}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="4%" />
                      <TableCell width="35%">Nome do grupo</TableCell>
                      <TableCell width="35%">TAG</TableCell>
                      <TableCell width="8%">Status</TableCell>
                      <TableCell width="8%">Necessário</TableCell>
                      <TableCell width="10%" align="right">
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((cookieGroup) => (
                      <CookieGroupRow
                        cookieGroup={cookieGroup}
                        key={cookieGroup?.id}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </PerfectScrollbar>
          </Box>
        </Grid>
      </Box>
      <Box px={2} display="flex" justifyContent="flex-end">
        {!isLoading && (
          <TablePagination
            component="div"
            count={meta?.totalCount || 0}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={params?.page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 15, 25]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        )}
      </Box>
    </>
  )
}

export default CookiesTable
