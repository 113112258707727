import { colors } from '@material-ui/core'

const styles = (theme) => ({
  scriptBox: {
    backgroundColor: colors.grey[200],
    padding: '10px',
    borderRadius: '10px',
  },
  customTextArea: {
    width: '100%',
    border: 'none',
    backgroundColor: colors.grey[200],
    color: colors.grey[700],
    fontWeight: 700,
    overflow: 'auto',
    outline: 'none',
    boxShadow: 'none',
    resize: 'none',
    height: '50px',
  },
})

export default styles
