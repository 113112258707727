import React from 'react'

import useSnackbar from 'hooks/useSnackbar'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import Form from './components/Form'

import * as service from 'service'

const DialogUser = ({
  open,
  setOpen = () => {},
  onEvent = () => {},
  ...props
}) => {
  const snackbar = useSnackbar()

  const onSubmit = async (user) => {
    try {
      await service.cookie.users.create({ user })

      snackbar.open({
        message: 'Usuário criado com sucesso!',
        variant: 'success',
      })

      onEvent()
      setOpen(false)
    } catch {
      snackbar.open({
        message: 'Falha ao criar usuário!',
        variant: 'error',
      })

      setOpen(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">Adicionar usuário</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Form onSubmit={onSubmit} user={props.user} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form="user-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogUser.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
}

export default DialogUser
