import React, { useState, useEffect } from 'react'

import {
  Box,
  makeStyles,
  Hidden,
  Typography,
  Grid,
  useTheme,
  Avatar,
  useMediaQuery,
} from '@material-ui/core'

import styles from './styles'

import smartphoneSVG from 'images/smartphone-2.svg'

const useStyles = makeStyles(styles)

const ItemStyledComponent = ({ number, text }) => {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" m={4}>
      <Box mr={3}>
        <Avatar className={classes.numberColor}>{number}</Avatar>
      </Box>
      <Box>
        <Typography className={classes.textItem}>{text}</Typography>
      </Box>
    </Box>
  )
}

const Innovation = () => {
  const classes = useStyles()

  const getWidth = () => {
    const keys = [...theme.breakpoints.keys].reverse()
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key))
        return !output && matches ? key : output
      }, null) || 'xs'
    )
  }

  const theme = useTheme()
  const [classesDynamic, setClassesDynamic] = useState()
  const [width, setWidth] = useState(getWidth())

  useEffect(() => {
    const setClasse = () => {
      if (['xs', 'sm'].includes(width)) {
        setClassesDynamic(classes.smallRoot)
      } else {
        setClassesDynamic(classes.bigRoot)
      }
    }
    setClasse()
    setWidth(width)
    //eslint-disable-next-line
  }, [width])

  return (
    <Grid className={classes.root}>
      <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
        <Box>
          <Box display="flex" justifyContent="center" width="100%" mb={2}>
            <Typography variant="body2" className={classes.labelSession}>
              {'Inovação'.toUpperCase()}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <Typography variant="h1" className={classes.title}>
              Vantagens em adquirir nossa tecnologia
            </Typography>
          </Box>
        </Box>
      </div>
      <Grid item xs={12} className={classesDynamic}>
        <Hidden mdDown>
          <div data-aos="fade-right" data-aos-anchor-placement="bottom-bottom">
            <Grid item xs={7}>
              <Box display="flex" justifyContent="flex-start">
                <img src={smartphoneSVG} alt="smartphone-2" width="125%" />
              </Box>
            </Grid>
          </div>
        </Hidden>
        <Grid item md={5} className={classes.itemsContainer}>
          <div data-aos="fade-left" data-aos-anchor-placement="bottom-bottom">
            <ItemStyledComponent
              number="01"
              text="Integração para diversas plataformas web"
            />
            <ItemStyledComponent
              number="02"
              text="Configure sua caixa de Cookies"
            />
            <ItemStyledComponent
              number="03"
              text="Acesso gratuito e escalável de acordo com seu negócio"
            />
            <ItemStyledComponent
              number="04"
              text="Leavening cookies sempre atualizada"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Innovation
