import { Check as CheckIcon, X as XIcon } from 'react-feather'

const statusIcon = (accepted) => {
  if (accepted) {
    return <CheckIcon color="white" />
  }

  if (!accepted) {
    return <XIcon color="white" />
  }

  return null
}

export default statusIcon
