import api from 'service/api'

const cookieAPI = api.create('cookie')

const getScriptTag = async () => {
  return await cookieAPI.get('/script_tag')
}
const getCustomBox = async ({ token }) => {
  return await cookieAPI.get(`/show_custom_cookie_box?token=${token}`)
}

const updateCustomBox = async ({ customBoxId, ...data }) => {
  return await cookieAPI.put(`/update_custom_cookie_box/${customBoxId}`, {
    customCookieBox: data,
  })
}

const cookieComponents = { getScriptTag, getCustomBox, updateCustomBox }

export default cookieComponents
