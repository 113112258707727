import React, { useEffect } from 'react'

import {
  Grid,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
} from '@material-ui/core'

import { Controller, useFormContext } from 'react-hook-form'

const CookieUnitForm = ({
  cookieUnit = null,
  index,
  removeCookieUnit = () => {},
}) => {
  const { control, errors, setValue } = useFormContext()

  useEffect(() => {
    setValue(`cookieUnits[${index}].name`, cookieUnit?.name)
    setValue(`cookieUnits[${index}].status`, cookieUnit?.status)
    setValue(`cookieUnits[${index}].description`, cookieUnit?.description)
    setValue(`cookieUnits[${index}].required`, cookieUnit?.required)
    // eslint-disable-next-line
  }, [cookieUnit])

  return (
    <Box my={1}>
      <Grid container spacing={2}>
        <Controller
          as={<></>}
          control={control}
          name={`cookieUnits[${index}].id`}
        />
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Nome"
                color="primary"
                variant="outlined"
                error={errors.cookieUnits && !!errors?.cookieUnits[index]?.name}
                helperText={
                  errors?.cookieUnits &&
                  errors?.cookieUnits[index]?.name &&
                  'Preencha este campo'
                }
                fullWidth
              />
            }
            rules={{ required: true, minLength: 1 }}
            defaultValue={cookieUnit?.name || ''}
            control={control}
            name={`cookieUnits[${index}].name`}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Descrição"
                color="primary"
                variant="outlined"
                error={
                  errors.cookieUnits &&
                  !!errors?.cookieUnits[index]?.description
                }
                helperText={
                  errors?.cookieUnits &&
                  errors?.cookieUnits[index]?.description &&
                  'Preencha este campo'
                }
                fullWidth
              />
            }
            rules={{ required: true }}
            defaultValue={cookieUnit?.description || ''}
            control={control}
            name={`cookieUnits[${index}].description`}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Ativo"
              />
            }
            control={control}
            name={`cookieUnits[${index}].status`}
            defaultValue={cookieUnit?.status}
          />
          <Controller
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Necessário"
              />
            }
            control={control}
            name={`cookieUnits[${index}].required`}
            defaultValue={cookieUnit?.required}
          />
        </Grid>
      </Grid>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <Button
          type="button"
          variant="outlined"
          onClick={() => removeCookieUnit(index)}
        >
          Remover
        </Button>
      </Box>
    </Box>
  )
}

export default CookieUnitForm
