import React from 'react'

import { Box, Typography, makeStyles, Button } from '@material-ui/core'

import styles from './styles'

// import { getBaseURL } from 'service/env'

const useStyles = makeStyles(styles)

const Contact = ({ signUpButton = () => {} }) => {
  const classes = useStyles()

  // const oneIdBaseURL = getBaseURL('oneId')

  return (
    <>
      <div data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          className={classes.firstContainer}
        >
          <Box textAlign="center">
            <Typography variant="h2">
              O que você achou da Leavening Cookies?
            </Typography>
          </Box>
        </Box>
      </div>
      <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
        <Box
          alignItems="center"
          justifyContent="center"
          className={classes.secondContainer}
          textAlign="center"
        >
          <Box mt={2}>
            <Typography color="primary" variant="h4">
              Agilidade, Praticidade e Resultados você encontra na Leavening
              Cookies, está pronto para entrar nessa nova experiência?
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" width="100%" mt={3}>
            <Box m={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => signUpButton()}
              >
                Criar conta
              </Button>
            </Box>
            {/* Implementar quando o site www.leavening.com estiver pronto */}
            {/* <Box m={2}>
              <Button variant="contained">Entre em contato</Button>
            </Box> */}
          </Box>
        </Box>
      </div>
    </>
  )
}

export default Contact
