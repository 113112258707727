import * as yup from 'yup'

export const schema = yup.object().shape({
  backgroundColor: yup.string(),
  mainTextColor: yup.string(),
  buttonTextColor: yup.string(),
  buttonBackgroundColor: yup.string(),
})

export default schema
