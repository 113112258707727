import React from 'react'

import { useForm, Controller } from 'react-hook-form'

import { Grid, TextField, Box, MenuItem, Typography } from '@material-ui/core'

import schema from './schema'

const Form = ({ onSubmit = () => {}, ...props }) => {
  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: props?.website
      ? {
          domain: props?.website?.domain || '',
          confirmed:
            props?.website?.confirmed !== undefined
              ? props.website.confirmed.toString()
              : '',
        }
      : {},
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="domain-form">
      <Grid container spacing={2} justify="flex-start" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box mt={1} mr={2}>
            <Controller
              as={
                <TextField
                  label="Domínio"
                  color="primary"
                  variant="outlined"
                  error={!!errors.domain}
                  helperText={errors?.domain?.message}
                  fullWidth
                />
              }
              control={control}
              defaultValue={props?.website?.domain || ''}
              name="domain"
              mode="onBlur"
            />
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={10}>
          <Box mt={1}>
            <Controller
              as={
                <TextField
                  label="Confirmado?"
                  select
                  color="primary"
                  variant="outlined"
                  error={!!errors.confirmed}
                  helperText={errors?.confirmed?.message ?? ''}
                  fullWidth
                >
                  <MenuItem value="false">
                    <Typography>Não</Typography>
                  </MenuItem>
                  <MenuItem value="true">
                    <Typography>Sim</Typography>
                  </MenuItem>
                </TextField>
              }
              control={control}
              defaultValue={
                props?.website?.confirmed
                  ? props?.website?.confirmed.toString()
                  : ''
              }
              name="confirmed"
              mode="onBlur"
            />
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
