import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { ColorPicker } from 'components'

import CookieBoxPreview from '../CookieBoxPreview'
import DialogResetFormConfirm from '../DialogResetFormConfirm'
import useSnackbar from 'hooks/useSnackbar'
import useCustomCookieBox from 'hooks/useCustomCookieBox'

import constants from 'constants/index'
import schema from './schema'
import * as service from 'service'
import {
  Maximize2 as MaximizeIcon,
  Minimize2 as MinimizeIcon,
} from 'react-feather'
import CookieGroupsProvider from 'providers/CookieGroupsProvider'

export const Form = ({ customCookieBox }) => {
  const [resetOpen, setResetOpen] = useState(false)
  const [previewFixed, setPreviewFixed] = useState(false)

  const { control, handleSubmit, watch, setValue, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      backgroundColor:
        customCookieBox?.backgroundColor ||
        constants.customCookeBox.defaultBoxColors.backgroundColor,
      mainTextColor:
        customCookieBox?.mainTextColor ||
        constants.customCookeBox.defaultBoxColors.mainTextColor,
      buttonTextColor:
        customCookieBox?.buttonTextColor ||
        constants.customCookeBox.defaultBoxColors.buttonTextColor,
      buttonBackgroundColor:
        customCookieBox?.buttonBackgroundColor ||
        constants.customCookeBox.defaultBoxColors.buttonBackgroundColor,
    },
  })

  const { loadData } = useCustomCookieBox()
  const snackbar = useSnackbar()

  const onSubmit = async (data) => {
    for (let key in data) {
      if (data[key].hex) {
        data[key] = `#${data[key].hex}`
      }
    }
    try {
      await service.cookie.cookieComponents.updateCustomBox({
        customBoxId: customCookieBox.id,
        ...data,
      })
      loadData()
      snackbar.open({
        variant: 'success',
        message: 'Sua caixa de cookie foi personalizada com sucesso',
      })
    } catch (err) {
      snackbar.open({
        variant: 'error',
        message: 'Ocorreu um erro ao salvar sua personalização.',
      })
    }
  }

  const handleCancel = () => {
    reset(customCookieBox)
  }

  const resetValues = () => {
    const defaultTheme = {
      backgroundColor:
        constants.customCookeBox.defaultBoxColors.backgroundColor,
      mainTextColor: constants.customCookeBox.defaultBoxColors.mainTextColor,
      buttonTextColor:
        constants.customCookeBox.defaultBoxColors.buttonTextColor,
      buttonBackgroundColor:
        constants.customCookeBox.defaultBoxColors.buttonBackgroundColor,
    }
    const keys = Object.keys(defaultTheme)
    const values = Object.values(defaultTheme)

    keys.map((key, index) => {
      return setValue(key, values[index])
    })
    setResetOpen(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Grid container>
            <Box display="flex" width="100%" m={2} flexDirection="column">
              <Controller
                as={<ColorPicker label="Cor de fundo" />}
                control={control}
                name="backgroundColor"
                mode="onBlur"
              />
              <Controller
                as={<ColorPicker label="Cor do texto principal" />}
                control={control}
                name="mainTextColor"
                mode="onBlur"
              />
              <Controller
                as={<ColorPicker label="Cor do texto do botão" />}
                control={control}
                name="buttonTextColor"
                mode="onBlur"
              />
              <Controller
                as={<ColorPicker label="Cor do fundo do botão" />}
                control={control}
                name="buttonBackgroundColor"
                mode="onBlur"
              />
              <Box
                mt={3}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Box display="flex">
                  <Box pl={2}>
                    <Button
                      color="secondary"
                      type="button"
                      onClick={() => setResetOpen(true)}
                      variant="contained"
                    >
                      Redefinir
                    </Button>
                  </Box>
                  <Box pl={2}>
                    <Button
                      color="secondary"
                      type="button"
                      onClick={() => handleCancel()}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
                <Button color="primary" type="submit" variant="contained">
                  Salvar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Card>
        <Box mt={2}>
          <Card>
            <Grid container spacing={1}>
              <Box display="flex" flexDirection="column" width="100%" m={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5">
                    Pré-visualizar caixa de aviso
                  </Typography>
                  <Tooltip title="Fixar pré-visualização" aria-label="add">
                    <IconButton onClick={() => setPreviewFixed(!previewFixed)}>
                      {previewFixed ? (
                        <MinimizeIcon size={'20'} />
                      ) : (
                        <MaximizeIcon size={'20'} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
                <CookieGroupsProvider>
                  <CookieBoxPreview
                    watch={watch}
                    setFixed={setPreviewFixed}
                    fixed={previewFixed}
                  />
                </CookieGroupsProvider>
              </Box>
            </Grid>
          </Card>
        </Box>
      </form>
      <DialogResetFormConfirm
        resetForm={resetValues}
        open={resetOpen}
        setOpen={setResetOpen}
      />
    </>
  )
}

export default Form
