import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

const ConfirmationDeleteDialog = ({
  open,
  headMessage,
  bodyMessage,
  setOpen = () => {},
  onClick = () => {},
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            {headMessage || 'Deletar registro?'}
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <Typography variant="body1">
            {bodyMessage || 'Realmente deseja deletar esse registro?'}
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button onClick={() => onClick()} variant="contained" color="primary">
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDeleteDialog
