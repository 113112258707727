const statusLabel = (accepted) => {
  if (accepted) {
    return 'Aceito'
  }

  if (!accepted) {
    return 'Não aceito'
  }

  return '-'
}

export default statusLabel
