import api from 'service/api'

const cookieAPI = api.create('cookie', true, false)

const get = async ({ websiteId = '', ...params }) => {
  return await cookieAPI.get(`websites/${websiteId}`, {
    params,
  })
}

const create = async ({ ...data }) => {
  return await cookieAPI.post('/websites', data)
}

const put = async ({ websiteId = '', ...data }) => {
  return await cookieAPI.put(`/websites/${websiteId}`, data)
}

const websites = { get, create, put }

export default websites
