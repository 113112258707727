import api from 'service/api'

const cookieAPI = api.create('cookie', true, true)

const get = async ({ cookieGroupId = '', ...params }) => {
  return await cookieAPI.get(`cookie_groups/${cookieGroupId}`, {
    params,
  })
}

const create = async (data) => {
  return await cookieAPI.post('/cookie_groups', { cookieGroup: data })
}

const put = async ({ cookieGroupId = '', ...data }) => {
  return await cookieAPI.put(`cookie_groups/${cookieGroupId}`, data)
}

const changeGroupStatus = async ({ cookieGroupId }) => {
  return await cookieAPI.put(`/cookie_group/${cookieGroupId}/change_status`)
}

const createCookieUnit = async ({ cookieGroupId = '', ...data }) => {
  return await cookieAPI.post(
    `/cookie_group/${cookieGroupId}/add_cookie_units`,
    { cookieGroup: { cookieUnits: [data] } },
  )
}

const putCookieUnit = async ({ cookieGroupId = '', cookieUnitId, ...data }) => {
  return await cookieAPI.put(
    `/cookie_group/${cookieGroupId}/cookie_unit/${cookieUnitId}`,
    { cookieUnit: data },
  )
}

const cookieGroups = {
  get,
  create,
  put,
  changeGroupStatus,
  putCookieUnit,
  createCookieUnit,
}

export default cookieGroups
