import React from 'react'

import { useForm, Controller } from 'react-hook-form'

import { Grid, TextField, Box } from '@material-ui/core'

import schema from './schema'

const Form = ({ onSubmit = () => {}, ...props }) => {
  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {},
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="user-form">
      <Grid container spacing={2} justify="flex-start" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box mt={1} mr={2}>
            <Controller
              as={
                <TextField
                  label="Email"
                  color="primary"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  fullWidth
                />
              }
              control={control}
              defaultValue={props?.user?.email || ''}
              name="email"
              mode="onBlur"
            />
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
