import React from 'react'

import { Box, Typography } from '@material-ui/core'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <Box m={4} display="flex" justifyContent="center">
      <Typography>
        © {year} Leavening | Políticas de Privacidade | Termos de Uso
      </Typography>
    </Box>
  )
}

export default Footer
