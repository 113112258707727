import api from 'service/api'
import humps from 'humps'
import history from 'service/history'
import { AUTH_TOKEN, ACCOUNT_TOKEN } from 'service/constants'

const cookieAPI = api.create('cookie', false)

const login = async (data) => {
  const response = await cookieAPI.post('/authentication', data)
  const token = response.data.authToken
  setToken(token)

  return response
}

const forgotPassword = async (data) => {
  const response = await cookieAPI.post('/users/password', {
    api_user: data,
  })

  return response
}

const setToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token)
}

const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN)
}

const setAccountToken = (accountToken) => {
  localStorage.setItem(ACCOUNT_TOKEN, accountToken)
}

const getAccountToken = () => {
  return localStorage.getItem(ACCOUNT_TOKEN)
}

const logout = () => {
  history.push('/')
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(ACCOUNT_TOKEN)
}

const getUserIdFromToken = () => {
  const token = getToken()
  const payload = humps.camelizeKeys(JSON.parse(atob(token.split('.')[1])))

  return payload.userId
}

const isAuthenticated = () => {
  const token = getToken()
  return !!token
}

const auth = {
  login,
  forgotPassword,
  logout,
  getToken,
  setToken,
  isAuthenticated,
  getUserIdFromToken,
  setAccountToken,
  getAccountToken,
}

export default auth
