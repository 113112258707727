import React from 'react'
import { ColorPicker as ColorPickerMaterial } from 'material-ui-color'
import { Box, Typography } from '@material-ui/core'
import constants from 'constants/index'

const ColorPicker = ({ label, name, ...props }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      m={1}
    >
      <Typography variant="h5">{label}</Typography>
      <ColorPickerMaterial
        value="#000"
        hideTextfield
        palette={constants.customCookeBox.collorPickerPalette}
        {...props}
      />
    </Box>
  )
}

export default ColorPicker
