import { useContext } from 'react'

import CustomCookieBoxContext from '../contexts/CustomCookieBoxContext'

const useCustomCookieBoxContext = () => {
  const context = useContext(CustomCookieBoxContext)

  if (context === undefined) {
    throw new Error(
      'useCustomCookieBoxContext must be used within a CustomCookieBoxContext.Provider',
    )
  }

  return {
    data: context.data,
    setData: context.setData,
    loadData: context.loadData,
    isLoading: context.isLoading,
    setIsLoading: context.setIsLoading,
  }
}

export default useCustomCookieBoxContext
