import React from 'react'
import { Grid, Typography, Tooltip, makeStyles } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const Label = ({
  title = '',
  description = '',
  children,
  className = '',
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid
      {...rest}
      className={`${classes.root} ${classes.textElipsiss} ${className}`}
    >
      <Typography variant="subtitle2" color="textPrimary">
        {title}
      </Typography>
      <Tooltip noWrap title={description} placement="bottom-start">
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      </Tooltip>

      {children}
    </Grid>
  )
}

export default Label
