const styles = (theme) => ({
  logo: {
    width: '145px',
    height: '37px',
  },
  appBar: {
    backgroundColor: theme.palette.white,
  },
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
  },
})

export default styles
