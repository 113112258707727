import React from 'react'
import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import Form from './components/Form'

import * as service from 'service'

const DialogDomain = ({
  open,
  setOpen = () => {},
  onEvent = () => {},
  ...props
}) => {
  const snackbar = useSnackbar()

  const onSubmit = async (website) => {
    if (props?.website?.id) {
      try {
        await service.cookie.websites.put({
          websiteId: props.website.id,
          website,
        })

        snackbar.open({
          message: 'Domínio atualizado com sucesso!',
          variant: 'success',
        })

        onEvent()
        setOpen(false)
      } catch {
        snackbar.open({
          message: 'Falha ao atualizar domínio!',
          variant: 'error',
        })
        setOpen(false)
      }
    } else {
      try {
        await service.cookie.websites.create({ website })

        snackbar.open({
          message: 'Domínio criado com sucesso!',
          variant: 'success',
        })

        onEvent()
        setOpen(false)
      } catch {
        snackbar.open({
          message: 'Falha ao criar domínio!',
          variant: 'error',
        })

        setOpen(false)
      }
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            {props?.website?.id ? 'Editar' : 'Adicionar'} domínio
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Form onSubmit={onSubmit} website={props.website} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form="domain-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogDomain.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
}

export default DialogDomain
