import React, { useState } from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { ConfirmationDeleteDialog } from 'components'

import { Button, Box, CircularProgress } from '@material-ui/core'

import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

const TableRowUser = ({ user, onEvent = () => {}, ...rest }) => {
  const [deleteDialog, setDeleteDialog] = useState(false)

  const snackbar = useSnackbar()

  const handleRemove = async ({ user, setDeleteDialog }) => {
    try {
      await service.cookie.users.remove({ userId: user.id })
      snackbar.open({
        variant: 'success',
        message: 'Usuário removido com sucesso!',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: 'Erro ao remover usuário.',
      })
    }
    onEvent()
    setDeleteDialog(false)
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{user.id}</TableCell>
        <TableCell>{user.name}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setDeleteDialog(true)}
          >
            Remover
          </Button>
        </TableCell>
      </TableRow>
      <ConfirmationDeleteDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        headMessage="Remover usuário"
        bodyMessage="Tem certeza que deseja remover este usuário?"
        onClick={() => {
          handleRemove({ user, setDeleteDialog })
        }}
      />
    </>
  )
}

const TableUsers = ({ users, isLoading, onEvent = () => {} }) => {
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum usuário encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Id</TableCell>
                  <TableCell width="40%">Nome</TableCell>
                  <TableCell width="30%">Email</TableCell>
                  <TableCell width="20%" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((user) => (
                  <TableRowUser
                    user={user}
                    key={user.id}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

export default TableUsers
