const styles = (theme) => ({
  bigRoot: {
    display: 'flex',
    width: '100%',
    height: '78vh',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
  },
  smallRoot: {
    display: 'flex',
    width: '100%',
    height: '78vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
  },
  labelSession: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
})

export default styles
