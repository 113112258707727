import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import {
  AppBar,
  IconButton,
  Toolbar,
  SvgIcon,
  Box,
  makeStyles,
  Hidden,
} from '@material-ui/core'

import { Account } from './components'

import { Menu as MenuIcon } from 'react-feather'

import styles from './styles'
import Logo from 'images/logo-leavening.png'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box mb={1}>
          <Hidden mdDown>
            <RouterLink to={routes.home}>
              <img
                alt="Logo"
                src={Logo}
                className={classes.logo}
                width="100%"
              />
            </RouterLink>
          </Hidden>
        </Box>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
