const styles = () => ({
  image: {
    width: '100%',
  },
  textLinkColor: {
    color: '#856A16',
    fontWeight: '700',
  },
  title: {
    fontSize: '3rem',
    fontWeight: '700',
  },
})

export default styles
