import React, { useState } from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import {
  Box,
  CircularProgress,
  Chip,
  makeStyles,
  IconButton,
  Collapse,
} from '@material-ui/core'

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

import VisitorsCookieTable from '../VisitorsCookieTable'

import helpers from 'helpers'

import styles from './styles'

const useStyles = makeStyles(styles)

const TableRowVisit = ({ visit, ...rest }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  return (
    <>
      <TableRow {...rest}>
        <TableCell width="5%">
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{visit.cookieRef}</TableCell>
        <TableCell>{visit?.website?.domain}</TableCell>
        <TableCell>
          <Chip
            label={helpers.visits.statusLabel(visit.accepted)}
            icon={helpers.visits.statusIcon(visit.accepted)}
            className={helpers.visits.statusChipColor(visit.accepted, classes)}
          />
        </TableCell>
        <TableCell>{helpers.formatters.date(visit.createdAt)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.containerCollapsableTable} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mb={2}>
              <VisitorsCookieTable visitorCookies={visit?.visitorCookies} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const VisitsTable = ({ visits, isLoading, onEvent = () => {} }) => {
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar options={{ useBothWheelAxes: true }}>
            <Table
              size="small"
              emptyMessage="Nenhum aceite de cookie encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="5%"></TableCell>
                  <TableCell width="30%">Referência do cookie</TableCell>
                  <TableCell width="30%">Domínio</TableCell>
                  <TableCell width="20%">Status</TableCell>
                  <TableCell width="15%">Data do aceite</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visits?.map((visit) => (
                  <TableRowVisit visit={visit} key={visit.cookieRef} hover />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

export default VisitsTable
