import React, { useState, useEffect } from 'react'

import {
  Box,
  makeStyles,
  Hidden,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'

import styles from './styles'

import smartphoneSVG from 'images/smartphone-1.svg'

const useStyles = makeStyles(styles)

const Introduction = () => {
  const classes = useStyles()

  const getWidth = () => {
    const keys = [...theme.breakpoints.keys].reverse()
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key))
        return !output && matches ? key : output
      }, null) || 'xs'
    )
  }

  const theme = useTheme()
  const [classesDynamic, setClassesDynamic] = useState()
  const [width, setWidth] = useState(getWidth())

  useEffect(() => {
    const setClasse = () => {
      if (['xs', 'sm'].includes(width)) {
        setClassesDynamic(classes.smallRoot)
      } else {
        setClassesDynamic(classes.bigRoot)
      }
    }
    setClasse()
    setWidth(width)
    //eslint-disable-next-line
  }, [width])

  return (
    <Grid item xs={12} className={classesDynamic}>
      <Box md={6}>
        <div data-aos="fade-right" data-aos-anchor-placement="bottom-bottom">
          <Box mt={5}>
            <Typography variant="body2" className={classes.labelSession}>
              {'introdução'.toUpperCase()}
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="h1" className={classes.title}>
              Leavening Cookies
            </Typography>
          </Box>
          <Box mt={3} maxWidth="500px">
            <Typography variant="body1" color="secondary">
              O gerenciador Leavening Cookies de consentimento de cookies visa
              atender os quesitos da LGPD, pode ser implementado em qualquer
              site de maneira prática para qualquer segmento do seu negócio.
            </Typography>
            <Grid item xs={6}>
              <Box display="flex" mt={['xs', 'sm'].includes(width) ? 5 : 0}>
                <Box mr={5}>
                  <Box>
                    <Typography variant="h1" className={classes.labelSession}>
                      Ágil
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography
                      variant="body2"
                      className={classes.subtitle}
                      color="secondary"
                    >
                      {'Tecnologia'.toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
                <Box mr={5}>
                  <Box>
                    <Typography variant="h1" className={classes.labelSession}>
                      Gratuita
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography
                      variant="body2"
                      className={classes.subtitle}
                      color="secondary"
                    >
                      {'Ferramenta'.toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </div>
      </Box>
      <Hidden mdDown>
        <Grid item xs={6}>
          <div data-aos="fade-left" data-aos-offset="300">
            <Box display="flex" justifyContent="flex-end">
              <img src={smartphoneSVG} alt="smartphone-1" />
            </Box>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default Introduction
