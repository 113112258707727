import React from 'react'

import { Container, Box, CircularProgress } from '@material-ui/core'

import { Page, ContentHeader } from 'components'
import Form from './components/CustomizeCookieBoxForm'

import useCustomCookieBox from 'hooks/useCustomCookieBox'

const CustomizeCookieBoxMain = () => {
  const { data, isLoading } = useCustomCookieBox()

  return (
    <Page title="Personalizar caixa de aviso">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Sistema"
          title="Cookies"
          subtitle="Personalizar caixa de aviso"
        />

        {isLoading ? (
          <Box
            id="loadBox"
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Form customCookieBox={data} />
        )}
      </Container>
    </Page>
  )
}

export default CustomizeCookieBoxMain
