import React from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'

const DialogResetFormConfirm = ({
  open,
  setOpen = () => {},
  resetForm = () => {},
}) => {
  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={closeDialog} disableBackdropClick fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Confirmar redefinição</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mb={1}>
        <DialogContent>
          <Box>
            <Typography>
              Deseja realmente redefinir o estilo da sua caixa de aviso?
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button type="button" variant="outlined" onClick={closeDialog}>
            Cancelar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={resetForm}
        >
          Redefinir
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogResetFormConfirm
