import React, { useState } from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import {
  Button,
  Box,
  CircularProgress,
  Chip,
  makeStyles,
} from '@material-ui/core'

import styles from './styles'
import helpers from 'helpers'

import DialogDomain from '../DialogDomain'

const useStyles = makeStyles(styles)

const TableRowDomain = ({ domain, onEvent = () => {}, ...rest }) => {
  const classes = useStyles()
  const [dialogDomain, setDialogDomain] = useState(false)
  const [editableDomain, setEditableDomain] = useState()

  const handleEditDomain = (domain) => {
    setEditableDomain(domain)
    setDialogDomain(true)
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{domain.domain}</TableCell>
        <TableCell>
          <Chip
            label={domain.confirmed ? 'Sim' : 'Não'}
            className={
              domain.confirmed ? classes.confirmed : classes.notConfirmed
            }
          />
        </TableCell>
        <TableCell>{helpers.formatters.date(domain.createdAt)}</TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleEditDomain(domain)}
          >
            Editar
          </Button>
        </TableCell>
      </TableRow>
      <DialogDomain
        open={dialogDomain}
        setOpen={setDialogDomain}
        website={editableDomain}
        onEvent={onEvent}
      />
    </>
  )
}

const DomainsTable = ({ domains, isLoading, onEvent = () => {} }) => {
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum domínio encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="40%">Domínio</TableCell>
                  <TableCell width="20%">Confirmado</TableCell>
                  <TableCell width="20%">Data de criação</TableCell>
                  <TableCell width="20%" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {domains?.map((domain) => (
                  <TableRowDomain
                    domain={domain}
                    key={domain.domain}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

export default DomainsTable
