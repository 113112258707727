import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'

import styles from './styles'

import * as service from 'service'

const useStyles = makeStyles(styles)

const DialogIntegrationScript = ({ open, setOpen = () => {} }) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const classes = useStyles()
  const scriptRef = useRef(null)

  const { response, isLoading } = useFetch(
    service.cookie.cookieComponents.getScriptTag,
    {},
  )

  const closeDialog = () => {
    setOpen(false)
    history.push(routes.home)
  }

  const copyToClipBoard = () => {
    scriptRef.current.select()
    document.execCommand('copy')
    snackbar.open({
      variant: 'success',
      message: 'Código do aviso copiado com sucesso!',
    })
    setOpen(false)
    history.push(routes.home)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick
      fullWidth
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">Código do Aviso</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>
          <>
            {isLoading ? (
              <Box
                id="loadBox"
                display="flex"
                width="100%"
                minHeight="700px"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box>
                  <Typography>
                    Para disponibilizar o aviso de Cookies em seu site, copie e
                    insira o código abaixo em seu site:
                  </Typography>
                </Box>
                <Box mt={1} className={classes.scriptBox}>
                  <textarea
                    className={classes.customTextArea}
                    defaultValue={response?.data}
                    ref={scriptRef}
                    readOnly
                  />
                </Box>
              </>
            )}
          </>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => closeDialog()}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => copyToClipBoard()}
        >
          Copiar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogIntegrationScript
