import { useContext } from 'react'

import CookieGroupContext from '../contexts/CookieGroupsContext'

const useCookieGroup = () => {
  const context = useContext(CookieGroupContext)

  if (context === undefined) {
    throw new Error(
      'useCookieGroup must be used within a CookieGroupContext.Provider',
    )
  }

  return {
    modalOpen: context.modalOpen,
    setModalOpen: context.setModalOpen,
    data: context.data,
    meta: context.meta,
    isLoading: context.isLoading,
    params: context.params,
    cookieGroup: context.cookieGroup,
    cookieUnit: context.cookieUnit,
    cookieGroupId: context.cookieGroupId,
    isCookieGroup: context.isCookieGroup,
    reloadUnitTable: context.reloadUnitTable,
    handleNewGroup: context.handleNewGroup,
    handleEditGroup: context.handleEditGroup,
    handleEditUnit: context.handleEditUnit,
    handleNewUnit: context.handleNewUnit,
    setParams: context.setParams,
    setData: context.setData,
    loadData: context.loadData,
    setIsLoading: context.setIsLoading,
    setCookieUnitsByGroup: context.setCookieUnitsByGroup,
    setReloadUnitTable: context.setReloadUnitTable,
  }
}

export default useCookieGroup
