import api from 'service/api'

const cookieAPI = api.create('cookie', true, true)

const get = async ({ visitId = '', ...params }) => {
  return await cookieAPI.get(`visits/${visitId}`, {
    params,
  })
}

const visits = { get }

export default visits
