import React, { useState, useEffect } from 'react'

import CookieGroupsContext from '../contexts/CookieGroupsContext'

import * as service from 'service'

const CookieGroupsProvider = ({ children }) => {
  const [data, setData] = useState()
  const [meta, setMeta] = useState()
  const [isLoading, setIsLoading] = useState()
  const [params, setParams] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [cookieGroup, setCookieGroup] = useState(false)
  const [cookieUnit, setCookieUnit] = useState(false)
  const [isCookieGroup, setIsCookieGroup] = useState(true)
  const [cookieGroupId, setCookieGroupId] = useState(true)
  const [reloadUnitTable, setReloadUnitTable] = useState(true)

  useEffect(() => {
    if (!data) {
      loadData()
    }
    //eslint-disable-next-line
  }, [params?.perPage, params?.Page])

  const loadData = async (params = {}) => {
    if (params?.perPage) {
      setIsLoading(true)
      const response = await service.cookie.cookieGroups.get(params)
      setData(response?.data?.cookieGroups)
      setMeta(response?.data?.meta)
      setIsLoading(false)
    }
  }

  const handleNewGroup = () => {
    setCookieGroup()
    setCookieUnit()
    setIsCookieGroup(true)
    setModalOpen(true)
  }

  const handleEditGroup = (group) => {
    setIsCookieGroup(true)
    setCookieGroupId(group.id)
    setCookieGroup(group)
    setModalOpen(true)
  }

  const handleEditUnit = (cookieGroupId, unit) => {
    setIsCookieGroup(false)
    setCookieGroupId(cookieGroupId)
    setCookieUnit(unit)
    setModalOpen(true)
  }

  const handleNewUnit = (cookieGroupId) => {
    setCookieUnit()
    setCookieGroup()
    setIsCookieGroup(false)
    setCookieGroupId(cookieGroupId)
    setModalOpen(true)
  }

  const setCookieUnitsByGroup = async (cookieGroupId) => {
    const response = await service.cookie.cookieGroups.get({})
    const cookieGroupResponse = response?.data?.cookieGroups?.find(
      (cookieGroup) => parseInt(cookieGroup.id) === parseInt(cookieGroupId),
    )
    return cookieGroupResponse?.cookieUnits
  }

  return (
    <CookieGroupsContext.Provider
      value={{
        data,
        meta,
        isLoading,
        params,
        modalOpen,
        cookieGroup,
        cookieUnit,
        isCookieGroup,
        cookieGroupId,
        reloadUnitTable,
        handleNewGroup,
        handleEditGroup,
        handleEditUnit,
        handleNewUnit,
        setParams,
        setData,
        loadData,
        setIsLoading,
        setCookieUnitsByGroup,
        setModalOpen,
        setReloadUnitTable,
      }}
    >
      {children}
    </CookieGroupsContext.Provider>
  )
}

export default CookieGroupsProvider
