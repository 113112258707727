import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Fab, Tooltip } from '@material-ui/core'
import { Route, matchPath, useLocation } from 'react-router-dom'

import * as service from 'service'
import { routes } from 'Routes'
import { HelpCircle as HelpIcon } from 'react-feather'
import useAuth from 'hooks/useAuth'

const DefaultComponent = ({ children }) => <>{children}</>

const RouteWithLayout = ({
  auth,
  layout,
  provider,
  component: Component,
  permission,
  somePermissions,
  admin = false,
  redirect = true,
  ...rest
}) => {
  const { documentationLinks } = useAuth()

  const Layout = layout || DefaultComponent
  const Provider = provider || DefaultComponent
  const isAuthenticated = service.cookie.auth.isAuthenticated()
  const [showHelpButton, setShowHelpButton] = useState(false)
  const [helpUrl, setHelpUrl] = useState()
  const location = useLocation()

  useEffect(() => {
    const verifyMatch = (uri, url) => {
      if (!matchPath(location.pathname, { path: uri, exact: true })) {
        return
      }

      setShowHelpButton(true)
      return setHelpUrl(url)
    }

    const verifyMatchRoute = () => {
      setShowHelpButton(false)

      documentationLinks.map((documentationLink) => {
        return verifyMatch(
          documentationLink?.cookieUri,
          documentationLink?.documentationUrl,
        )
      })
    }

    verifyMatchRoute()
    //eslint-disable-next-line
  }, [[documentationLinks, location.key]])

  if (!auth && isAuthenticated && redirect) {
    service.history.push(routes.home)
    return null
  }

  if (auth && !isAuthenticated) {
    service.cookie.auth.logout()
    return null
  }

  const renderRoute = (matchProps) => {
    return (
      <Layout
        permission={permission}
        somePermissions={somePermissions}
        admin={admin}
      >
        <Provider component={Provider}>
          <Component {...matchProps} />
          {showHelpButton && (
            <Box
              diplay="flex"
              margin="20px"
              position="fixed"
              zIndex={'99999'}
              bottom="0"
              right="0"
            >
              <Tooltip title="Acessar documentação dessa página">
                <Fab
                  size="small"
                  color="secondary"
                  onClick={() => window.open(helpUrl, '_blank')}
                >
                  <HelpIcon />
                </Fab>
              </Tooltip>
            </Box>
          )}
        </Provider>
      </Layout>
    )
  }

  return <Route {...rest} render={(matchProps) => renderRoute(matchProps)} />
}

RouteWithLayout.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  path: PropTypes.string,
  permission: PropTypes.string,
  admin: PropTypes.bool,
}

export default RouteWithLayout
