import React, { useEffect, useState } from 'react'

import { Typography, Button } from '@material-ui/core'

import useCookieGroup from 'hooks/useCookieGroup'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

const CookieUnitRow = ({ cookieGroupId, cookieUnit }) => {
  const { handleEditUnit } = useCookieGroup()

  return (
    <TableRow>
      <TableCell>
        <Typography>{cookieUnit.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{cookieUnit?.description || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{cookieUnit.status ? 'Ativo' : 'Inativo'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{cookieUnit.required ? 'Sim' : 'Não'}</Typography>
      </TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          onClick={() => handleEditUnit(cookieGroupId, cookieUnit)}
        >
          Editar
        </Button>
      </TableCell>
    </TableRow>
  )
}

const CookieUnitTable = ({ cookieGroupId, cookieUnits }) => {
  const { reloadUnitTable, setCookieUnitsByGroup } = useCookieGroup()
  const [cookiesUnits, setCookiesUnits] = useState(cookieUnits)
  const [loading, setLoading] = useState()

  useEffect(() => {
    const reloadUnits = async () => {
      setLoading(true)
      const newCookieUnits = await setCookieUnitsByGroup(cookieGroupId)
      setCookiesUnits(newCookieUnits)
      setLoading(false)
    }

    reloadUnits()
    //eslint-disable-next-line
  }, [reloadUnitTable])

  return (
    <Table
      stickyHeader
      size="small"
      isLoading={loading}
      emptyMessage="Nenhum cookie foi encontrado nesse grupo"
    >
      <TableHead>
        <TableRow>
          <TableCell width="25%">Nome do cookie</TableCell>
          <TableCell width="25%">Descrição</TableCell>
          <TableCell width="20%">Status</TableCell>
          <TableCell width="20%">Necessário</TableCell>
          <TableCell width="10%" align="right">
            Ações
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cookiesUnits?.map((cookieUnit) => (
          <CookieUnitRow
            key={cookieUnit?.id}
            cookieGroupId={cookieGroupId}
            cookieUnit={cookieUnit}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default CookieUnitTable
