import api from 'service/api'

const cookieAPI = api.create('cookie')

const get = async ({ privacyPolicyLinkId = '', ...params }) => {
  return await cookieAPI.get(`privacy_policy_links/${privacyPolicyLinkId}`, {
    params,
  })
}

const create = async (data) => {
  return await cookieAPI.post('/privacy_policy_links', data)
}

const put = async ({ privacyPolicyLinkId = '', ...data }) => {
  return await cookieAPI.put(
    `privacy_policy_links/${privacyPolicyLinkId}`,
    data,
  )
}

const privacyPolicyLinks = { get, create, put }

export default privacyPolicyLinks
