import React from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const Commentary = () => {
  const classes = useStyles()
  return (
    <>
      <div data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.firstContainer}
        >
          <Box width="50%" textAlign="center">
            <Typography variant="h3" className={classes.title}>
              Resolva seus problemas em minutos na adequação a LGPD com muita
              agilidade!
            </Typography>
          </Box>
        </Box>
      </div>
      {/* Implementar quando tiver um comentário*/}
      {/* <div data-aos='fade-up' data-aos-anchor-placement="bottom-bottom">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.secondContainer}
          >
          <Box m={2}>
            <Avatar src="" />
          </Box>
          <Box display="flex">
            <Box mr={2}>
              <Typography className={classes.fontStyle}>
                Binder Jackson,
              </Typography>
            </Box>
            <Typography color="secondary" className={classes.fontStyle}>
              co-creator of BinderDance®
            </Typography>
          </Box>
        </Box>
      </div> */}
    </>
  )
}

export default Commentary
