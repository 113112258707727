import { colors } from '@material-ui/core'

const styles = (theme) => ({
  confirmed: {
    backgroundColor: colors.green[500],
    color: colors.common.white,
    fontWeight: 700,
  },

  notConfirmed: {
    backgroundColor: colors.grey[500],
    color: colors.common.white,
    fontWeight: 700,
  },
})

export default styles
