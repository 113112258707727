const collorPickerPalette = {
  Vermelho: '#ff0000',
  Azul: '#0000ff',
  Verde: '#00ff00',
  Amarelo: 'yellow',
  Ciano: 'cyan',
  Cinza: 'gray',
  Laranja: 'orange',
  Roxo: 'purple',
  Preto: 'black',
  Branco: 'white',
  'Azul Escuro': 'darkblue',
}

const defaultBoxColors = {
  backgroundColor: '#333333',
  mainTextColor: '#FFFFFF',
  buttonTextColor: '#000000',
  buttonBackgroundColor: '#FEC311',
}

const customCookieBox = {
  collorPickerPalette,
  defaultBoxColors,
}

export default customCookieBox
