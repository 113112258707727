import React from 'react'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

import {
  Container,
  Box,
  Grid,
  Card,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'

import { Page, ContentHeader, FilterButton, Filters } from 'components'

import VisitsTable from './components/VisitsTable'

import * as service from 'service'

const VisitsMain = () => {
  const filter = useFilter()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const {
    perPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.cookie.visits.get,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Todos os aceites de cookies">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Sistema"
          title="Cookies"
          subtitle="Aceite de cookies"
        >
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <VisitsTable
                visits={response?.data.visits}
                isLoading={isLoading}
                onEvent={refresh}
              />
            </Grid>
          </Box>
          {response?.data?.meta && (
            <Box px={2} display="flex" justifyContent="flex-end">
              {!isLoading && response ? (
                <TablePagination
                  component="div"
                  count={response?.data?.meta?.totalCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              ) : (
                <Box py="11px">
                  <Skeleton variant="rect" width={200} height={30} />
                </Box>
              )}
            </Box>
          )}
        </Card>
        <Filters filter={filter}>
          <input
            textfieldinput="true"
            label="Referência do cookie"
            name="cookieRef"
          />
          <input
            datepickerinput="true"
            label="Data de aceite inicial"
            name="createdAtGt"
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de aceite final"
            name="createdAtLt"
            data-target="createdAtGt"
            data-max
          />
          <select textfieldinput="true" label="Status" name="accepted">
            <option value=""></option>
            <option value={true}>Aceito</option>
            <option value={false}>Não aceito</option>
          </select>
        </Filters>
      </Container>
    </Page>
  )
}

export default VisitsMain
