const styles = () => ({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '16px',
    letterSpacing: '0.3px',
  },
  link: {
    textDecoration: 'none !important',
  },
  button: {
    textTransform: 'inherit',
  },
  fixedStyles: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 10000,
    pointerEvents: 'none',
  },
  unfixedStyles: {
    marginTop: 2,
  },
})

export default styles
