import React from 'react'

import { Box, Typography, makeStyles, Grid, Avatar } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const TextCardComponent = ({ number, title, children }) => {
  const classes = useStyles()
  return (
    <>
      <Box display="flex" m={4}>
        <Box mr={3}>
          <Avatar className={classes.numberColor}>{number}</Avatar>
        </Box>
        <Box>
          <Typography className={classes.textItem}>{title}</Typography>
          <Box textAlign="justify" mt={1}>
            <Typography variant="body1" color="secondary">
              {children}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const Integration = () => {
  const classes = useStyles()
  return (
    <>
      <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
        <Box>
          <Box display="flex" justifyContent="center" width="100%" mb={2}>
            <Typography variant="body2" className={classes.labelSession}>
              {'Integração'.toUpperCase()}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <Typography variant="h1" className={classes.title}>
              Veja como é fácil a integração em 3 etapas
            </Typography>
          </Box>
        </Box>
      </div>
      <Box>
        <Box my={4}>
          <Grid container>
            <Grid item md={4} xs={12}>
              <div
                data-aos="fade-right"
                data-aos-anchor-placement="bottom-bottom"
              >
                <TextCardComponent number="01" title="Crie sua conta">
                  Para começar, cadastre-se no autenticador único OneID, e crie
                  sua conta no Leavening Cookies.
                </TextCardComponent>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <TextCardComponent
                  number="02"
                  title="Configure sua caixa de Cookies"
                >
                  Configure e personalize sua caixa de Cookies. Cadastre os
                  domínios dos sites em que deseja utilizá-la.
                </TextCardComponent>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div
                data-aos="fade-left"
                data-aos-anchor-placement="bottom-bottom"
              >
                <TextCardComponent number="03" title="Insira em seu site">
                  Copie o código gerado pelo Leavening Cookies, insira em seu
                  site e pronto! Sua caixa de cookies aparecerá e seu site
                  estará adequado a diversas leis de proteção de dados.
                </TextCardComponent>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default Integration
