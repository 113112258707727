import React from 'react'

import { include } from 'named-urls'

import { Switch, Redirect, Route } from 'react-router-dom'
import { RouteWithLayout } from './components'

import { Main as MainLayout } from './layouts'

import LandingPage from 'views/LandingPage'
import NotFound from 'views/NotFound'
import Forbidden from 'views/Forbidden'
import Home from 'views/Home'
import Visits from 'views/Visits'
import Cookies from 'views/ManageCookies'
import Domains from 'views/Domains'
import CustomizeCookieBox from 'views/CustomizeCookieBox'
import Users from 'views/Users'
import CustomCookieBoxProvider from 'providers/CustomCookieBoxProvider'
import SnackbarProvider from 'providers/SnackbarProvider'
import CookieGroupsProvider from 'providers/CookieGroupsProvider'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path={routes.root}
        component={LandingPage}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.users.main}
        component={Users.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.home}
        component={Home}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.visits.main}
        component={Visits.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.cookies.main}
        component={Cookies.Main}
        layout={MainLayout}
        provider={CookieGroupsProvider}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.customizeCookieBox.main}
        component={CustomizeCookieBox.Main}
        layout={MainLayout}
        provider={CustomCookieBoxProvider}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.domains.main}
        component={Domains.Main}
        layout={MainLayout}
        exact
        auth
      />
      <Route component={NotFound} exact path={routes.notFound} />
      <Route component={Forbidden} exact path={routes.forbidden} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}

export const routes = {
  root: '/',

  visits: include('/visits', {
    main: '',
  }),

  cookies: include('/cookies', {
    main: '',
  }),

  customizeCookieBox: include('/customize-cookie-box', {
    main: '',
  }),

  domains: include('/domains', {
    main: '',
  }),
  home: '/home',

  users: include('/users', {
    main: '',
  }),

  notFound: '/not-found',
  forbidden: '/forbidden',
}

export default Routes
