import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import Page from 'components/Page'
import { routes } from 'Routes'
import {
  TopBar,
  Commentary,
  Innovation,
  Contact,
  Footer,
  Integration,
  Introduction,
} from './components'
import { COMPANY_TOKEN_LOGIN_WITH_ID } from 'service/env'

import styles from './styles'

import { makeStyles, Container, Grid } from '@material-ui/core'

import AOS from 'aos'
import 'aos/dist/aos.css'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import useAuth from 'hooks/useAuth'

const useStyles = makeStyles(styles)

const LandingPage = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const snackbar = useSnackbar()
  const auth = useAuth()

  const locationState = location.state
  const message = locationState
    ? locationState.error || locationState.success
    : ''

  useEffect(() => {
    AOS.init({
      duration: 1250,
    })
  }, [])

  useEffect(() => {
    if (locationState) {
      if (locationState.error) {
        snackbar.open({ message: message, variant: 'error' })
      } else {
        snackbar.open({ message: message, variant: 'success' })
      }
    }
    // eslint-disable-next-line
  }, [message, locationState])

  const loginWithButton = async () => {
    const response = await window.getTokenAuth()

    await service.cookie.auth.login({
      companyToken: COMPANY_TOKEN_LOGIN_WITH_ID,
      token: response?.token,
    })
    auth.loadDataWithAnimation()
    history.push(routes.home)
  }

  const signUpButton = async () => {
    const response = await window.signUpOneID()
    snackbar.open({ message: response.message, variant: response.status })
  }

  return (
    <Page className={classes.root} title="Leavening Cookies">
      <input
        type="hidden"
        id="leavening_company_token"
        value={COMPANY_TOKEN_LOGIN_WITH_ID}
      />
      <TopBar loginWithButton={loginWithButton} signUpButton={signUpButton} />
      <Grid container className={classes.rootBody}>
        <Grid item>
          <Container maxWidth="md">
            <Introduction />
          </Container>
          <Container maxWidth="lg">
            <Innovation />
            <Integration />
          </Container>
        </Grid>
        <Grid item className={classes.grayContainer}>
          <Commentary />
        </Grid>
        <Grid item>
          <Container maxWidth="md">
            <Contact signUpButton={signUpButton} />
          </Container>
        </Grid>
        <Grid item className={classes.grayContainer}>
          <Container maxWidth="md">
            <Footer />
          </Container>
        </Grid>
      </Grid>
    </Page>
  )
}

export default LandingPage
