const styles = (theme) => ({
  labelSession: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  numberColor: {
    backgroundColor: theme.palette.primary.main,
  },
  textItem: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
})

export default styles
