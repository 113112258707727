import React, { useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  Divider,
  Button,
  Typography,
} from '@material-ui/core'

import CookiesForm from './CookiesForm'

const CookiesFormModal = ({
  open,
  cookieData,
  isCookieGroup = true,
  setOpen = () => {},
}) => {
  const edit = cookieData ? true : false
  const [loading, setLoading] = useState(false)

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">{`${edit ? 'Editar ' : 'Cadastrar novo '} ${
            isCookieGroup ? 'grupo de ' : ''
          }cookie`}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <CookiesForm
        cookieData={cookieData}
        edit={edit}
        isCookieGroup={isCookieGroup}
        setLoading={setLoading}
        setOpen={setOpen}
      >
        <Divider />
        <DialogActions p={2}>
          <Box display="flex" justifyContent="flex-end" width="100%" mr={2}>
            <Box mr={2}>
              <Button
                type="button"
                variant="outlined"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
            </Box>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Box>
        </DialogActions>
      </CookiesForm>
    </Dialog>
  )
}

export default CookiesFormModal
